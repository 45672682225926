import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import image from './cash_animation-small.webp';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/user-info', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.status === 200) {
          setIsLoggedIn(true);
        }
      } catch (err) {
        setIsLoggedIn(false);
      }
    };

    checkAuth();
  }, []);

  const handleLoginClick = () => {
    if (isLoggedIn) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-between bg-[rgb(53,68,89)] relative">
      <header className="w-full flex justify-between items-center p-4 z-10 lg:justify-center lg:space-x-8 bg-white">
        <h4 className="font-bold text-2xl text-[rgb(31,41,55)] lobster-regular">dealcutter.ai</h4>
        <button
          className="bg-gray-800 text-white rounded px-4 py-2 text-sm hover:opacity-80"
          onClick={handleLoginClick}
        >
          {isLoggedIn ? 'App' : 'Login'}
        </button>
      </header>
      <main className="flex flex-col items-center justify-center text-center flex-grow z-10 p-4 bg-[rgb(53,68,89)]">
        <h1 className="font-bold text-[40px] text-white mb-4">Everything is negotiable.</h1>
        <p className="text-[15px] text-white mb-4">(when you don’t have to do it)</p>
        <picture>
          <source srcSet={image} type="image/webp" />
          <img src={image} alt="AI Negotiation" className="w-1/2 mx-auto my-5" />
        </picture>
        <p className="text-base text-white mb-4">
          Our AI auto-negotiates for you through your email.
        </p>
        <button
          className="bg-green-500 text-gray-900 font-bold rounded py-3 px-6 w-3/4 hover:opacity-80 mb-4"
          onClick={handleRegisterClick}
        >
          Start in 2 min
        </button>
      </main>
      <footer className="w-full p-4 text-center z-10 bg-[rgb(53,68,89)]">
        <p className="text-white">
          <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link> |{' '}
          <Link to="/terms-of-service" className="hover:underline">Terms of Service</Link>
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
