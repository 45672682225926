import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-4">
      <div className="mb-4 text-center">
        <Link to="/">
          <h4 className="font-bold text-2xl text-black lobster-regular">dealcutter.ai</h4>
        </Link>
      </div>
      <div className="w-full max-w-3xl bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p>Welcome to DealCutter.ai. We value your privacy and strive to protect your personal information. This Privacy Policy explains how we collect, use, and share your information when you use our website and software applications.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Information We Collect</h2>
        <h3 className="text-xl font-semibold mt-4 mb-2">Personal Information</h3>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Contact Information</strong>: Name, email address, phone number.</li>
          <li><strong>Account Information</strong>: Username, password.</li>
          <li><strong>Payment Information</strong>: Credit card details, billing address (may use in the future).</li>
        </ul>

        <h3 className="text-xl font-semibold mt-4 mb-2">Usage Information</h3>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Device Information</strong>: IP address, browser type, operating system.</li>
          <li><strong>Log Information</strong>: Pages visited, time spent on the site, clickstream data.</li>
          <li><strong>Cookies and Tracking Technologies</strong>: Cookies, web beacons, and similar tracking technologies.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">How We Use Your Information</h2>
        <p>We use your information to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide and improve our services.</li>
          <li>Process transactions and send transaction-related information.</li>
          <li>Send promotional and marketing communications.</li>
          <li>Respond to customer service requests and support needs.</li>
          <li>Personalize your experience on our site.</li>
          <li>Conduct research and analysis.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">How We Share Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:</p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Service Providers</strong>: We may share information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.</li>
          <li><strong>Legal Requirements</strong>: We may disclose information if required to do so by law or in response to valid requests by public authorities.</li>
          <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Google User Data</h2>
        <p>We access, use, store, and share Google user data as follows:</p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Access</strong>: We access your Gmail account to retrieve product-related emails.</li>
          <li><strong>Use</strong>: We use the data to contact stores and negotiate discounts on your behalf. Any data we access is not used to develop, improve, or train generalized AI and/or ML models.</li>
          <li><strong>Store</strong>: We securely store your email interactions for the purpose of negotiation tracking and future reference.</li>
          <li><strong>Share</strong>: We do not share your Google user data with third parties, except for service providers who assist in our email processing and delivery services.</li>
          <li><strong>Compliance</strong>: Our use of Google user data is limited to the practices disclosed in this Privacy Policy and conforms with Google's Limited Use Requirements.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Security of Your Information</h2>
        <p>We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, access controls, and secure servers.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Your Choices</h2>
        <p>You have the right to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Access and update your personal information.</li>
          <li>Opt-out of receiving marketing communications.</li>
          <li>Delete your account and personal information.</li>
        </ul>
        <p>To exercise these rights, please contact us at <a href="mailto:support@dealcutter.ai" className="text-blue-500 hover:underline">support@dealcutter.ai</a>.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar tracking technologies to enhance your experience on our website. You can control the use of cookies through your browser settings.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Children's Privacy</h2>
        <p>Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <address className="not-italic mb-4">
          DealCutter.ai<br />
          <a href="mailto:support@dealcutter.ai" className="text-blue-500 hover:underline">support@dealcutter.ai</a><br />
          535 Queen St E<br />
          Toronto, ON M5A 1V1, Canada<br />
          Phone: (123) 456-7890
        </address>

        <p className="text-sm text-gray-600">This Privacy Policy was last updated on May 29, 2024.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
