import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ShoppingBagIcon, Cog8ToothIcon, Bars3Icon } from '@heroicons/react/24/solid';

const Sidebar = ({ isSidebarExpanded, toggleSidebar }) => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/api/user-info', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log("User info fetched", response.data.email);
                setEmail(response.data.email);
            } catch (err) {
                console.error('Failed to fetch user info', err);
                navigate('/login');
            }
        };

        fetchUserInfo();
    }, [navigate]);

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/api/logout', {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            localStorage.removeItem('token');
            navigate('/login');
        } catch (err) {
            console.error('Failed to logout', err);
        }
    };

    return (
        <>
            {!isSidebarExpanded && (
                <div className="fixed top-4 left-4 z-50 md:hidden" onClick={toggleSidebar}>
                    <Bars3Icon className="h-8 w-8 text-[rgb(53,68,89)]" />
                </div>
            )}
            <div className={`fixed inset-y-0 left-0 bg-[rgb(242,248,255)] border-r border-gray-100 transition-transform duration-300 ease-in-out transform ${isSidebarExpanded ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 md:w-64 w-1/2`}>
              <div className="flex items-center bg-[rgb(53,68,89)] justify-between p-4">
                  <div className="flex"></div>
                  <h2 className="text-2xl font-light text-white lobster-regular mx-auto">dealcutter.ai</h2>
                  <button onClick={toggleSidebar} className="md:hidden text-white">
                      <Bars3Icon className="h-8 w-8" />
                  </button>
              </div>
                <nav className="flex-1 mt-2">
                    <ul className="list-none p-0">
                        <li className="flex items-center p-4 hover:bg-gray-100">
                            <NavLink to="/home" className="flex items-center w-full text-gray-700" activeClassName="font-bold" onClick={toggleSidebar}>
                                <ShoppingBagIcon className="h-6 w-6" />
                                <span className={`${isSidebarExpanded ? 'block ml-2' : 'hidden'}`}>Shopping List</span>
                            </NavLink>
                        </li>
                        <li className="flex items-center p-4 hover:bg-gray-100">
                            <NavLink to="/settings" className="flex items-center w-full text-gray-700" activeClassName="font-bold" onClick={toggleSidebar}>
                                <Cog8ToothIcon className="h-6 w-6" />
                                <span className={`${isSidebarExpanded ? 'block ml-2' : 'hidden'}`}>Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="absolute inset-x-0 bottom-0 flex flex-col items-center p-4 border-t border-gray-200">
                    <button className="text-gray-600 text-sm hover:text-gray-800 mb-2 border border-[1px] border-[rgb(53,68,89)] rounded-[4px] px-3 py-1" onClick={handleLogout}>Logout</button>
                    <p className="text-gray-600 text-xs">{email}</p>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
