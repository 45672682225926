import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import loadingSpinner from './assets/loading_spinner_transparent.gif'; // Import the loading spinner

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('email_confirmed')) {
            setSuccessMessage('Email confirmed, please login.');
        }
    }, [location]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when form is submitted
        try {
            const response = await axios.post('/api/login', { username, password });
            const data = response.data;
            localStorage.setItem('token', data.access_token);
            if (!data.email || !data.phone_number) {
                navigate('/settings');
            } else {
                navigate('/home');
            }
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
        } finally {
            setLoading(false); // Set loading to false after request is complete
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[rgb(53,68,89)] p-4">
            <Link to="/" className="mb-4 text-center">
                <h4 className="font-bold text-2xl text-white lobster-regular">dealcutter.ai</h4>
            </Link>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-4">Login</h2>
                {location.state && location.state.message && (
                    <div className="mb-4 text-green-600">{location.state.message}</div>
                )}
                {successMessage && <div className="mb-4 text-green-600">{successMessage}</div>}
                {error && <div className="mb-4 text-red-600">{error}</div>}
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white py-2 rounded hover:bg-blue-700"
                        style={{ backgroundColor: 'rgb(31, 41, 55)' }}
                    >
                        {loading ? <img src={loadingSpinner} alt="Loading..." className="w-6 h-6 mx-auto" /> : 'Login'}
                    </button>
                </form>
                <div className="flex justify-between mt-4">
                    <a href="/forgot-password" className="hover:underline" style={{ color: 'rgb(31, 41, 55)' }}>Forgot Password?</a>
                    <a href="/register" className="hover:underline" style={{ color: 'rgb(31, 41, 55)' }}>Create Account</a>
                </div>
            </div>
        </div>
    );
};

export default Login;
