import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import loadingSpinner from './assets/loading_spinner_transparent.gif'; // Import the loading spinner

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        // Reset message and error states on component mount
        setMessage('');
        setError('');
    }, []);

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when form is submitted
        setError(''); // Reset error state before attempting password update
        try {
            const { error } = await supabase.auth.updateUser({
                password: password
            });
            if (error) throw error;
            setMessage('Password updated successfully. Please log in with your new password.');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setError('Failed to update password. Please try again.');
        } finally {
            setLoading(false); // Set loading to false after request is complete
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[rgb(53,68,89)] p-4">
            <Link to="/" className="mb-4 text-center">
                <h4 className="font-bold text-2xl text-white lobster-regular">dealcutter.ai</h4>
            </Link>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-4">Update Password</h2>
                {message && <p className="mb-4 text-green-600">{message}</p>}
                {error && <p className="mb-4 text-red-600">{error}</p>}
                <form onSubmit={handleUpdatePassword}>
                    <div className="mb-4">
                        <label className="block text-gray-700">New Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white py-2 rounded hover:bg-blue-700"
                        style={{ backgroundColor: 'rgb(31, 41, 55)' }}
                    >
                        {loading ? <img src={loadingSpinner} alt="Loading..." className="w-6 h-6 mx-auto" /> : 'Update Password'}
                    </button>
                </form>
                <div className="mt-4">
                    <a href="/login" className="hover:underline" style={{ color: 'rgb(31, 41, 55)' }}>Login</a>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
