import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Login from './Login';  // Add Login component
import Register from './Register';  // Add Register component
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import LandingPage from './landing-page/LandingPage';
import PrivacyPolicy from './landing-page/PrivacyPolicy';
import TermsOfService from './landing-page/TermsOfService';
import './global.css'; // Import the global CSS

ReactDOM.render(
    <Router>
        <Routes>
            <Route path="/landing-page" element={<LandingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/*" element={<App />} />
        </Routes>
    </Router>,
    document.getElementById('root')
);
