// Home.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ShoppingList from './ShoppingList';
import { useLocation } from 'react-router-dom';

const Home = () => {
    const [threads, setThreads] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const location = useLocation();
    const successMessage = location.state?.successMessage || '';

    useEffect(() => {
        window.scrollTo(0, 0); // Force scroll to top on component mount
        const fetchThreads = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/api/threads', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setThreads(response.data);
                setError('');
            } catch (err) {
                setError('Failed to fetch threads');
            }
            setLoading(false);
        };

        fetchThreads();
    }, []);

    useEffect(() => {
        if (successMessage) {
            setShowSuccessMessage(true);
            const timer = setTimeout(() => {
                setShowSuccessMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    return (
        <div className="px-4 py-2 md:px-8 md:py-4">
            {showSuccessMessage && (
                <div className="flex justify-center">
                    <div className="mt-4 p-4 bg-green-100 text-green-700 border border-green-400 rounded shadow-md">
                        {successMessage}
                    </div>
                </div>
            )}
            {error && <p className="text-red-500">{error}</p>}
            <ShoppingList threads={threads} loading={loading} />
        </div>
    );
};

export default Home;
