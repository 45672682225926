import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Home';
import Settings from './Settings';
import Sidebar from './Sidebar';
import './global.css'; // Import the global CSS which includes Tailwind CSS

const App = () => {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(window.innerWidth > 768);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && isSidebarExpanded) {
                setIsSidebarExpanded(false);
            } else if (window.innerWidth > 768 && !isSidebarExpanded) {
                setIsSidebarExpanded(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isSidebarExpanded]);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('No token');

                const response = await fetch('/api/check-auth', {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.ok) {
                    setIsAuthenticated(true);
                } else {
                    throw new Error('Not authenticated');
                }
            } catch (error) {
                console.error('Not authenticated', error);
                setIsAuthenticated(false);
                navigate('/login');
            }
        };
        checkAuth();
    }, [navigate]);

    const toggleSidebar = () => {
        if (window.innerWidth <= 768) {
            setIsSidebarExpanded(!isSidebarExpanded);
        }
    };

    if (!isAuthenticated) return null;

    return (
        <div className="flex h-screen">
            <Sidebar isSidebarExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            <div className="flex-1">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/settings" element={<Settings />} />
                    {/* Add other routes here */}
                </Routes>
            </div>
        </div>
    );
};

export default App;
