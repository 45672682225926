import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-4">
      <div className="mb-4 text-center">
        <Link to="/">
          <h4 className="font-bold text-2xl text-black lobster-regular">dealcutter.ai</h4>
        </Link>
      </div>
      <div className="w-full max-w-3xl bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
        <p>Welcome to DealCutter.ai. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using DealCutter.ai, you agree to be bound by these Terms.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Use of Our Service</h2>
        <p>You may use our services only in compliance with these Terms and all applicable laws. When you create your DealCutter.ai account, you must provide us with accurate and complete information. You are responsible for safeguarding your account and for all activities that occur under your account.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Privacy</h2>
        <p>Your privacy is important to us. Please review our <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a>, which describes how we collect, use, and protect your personal information.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Prohibited Activities</h2>
        <p>You agree not to engage in any of the following prohibited activities:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Using the service for any illegal purpose or in violation of any local, state, national, or international law.</li>
          <li>Harassing, threatening, or defrauding other users.</li>
          <li>Using automated means to interact with our services.</li>
          <li>Attempting to interfere with the security or integrity of our services.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Termination</h2>
        <p>We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason, including if you breach these Terms.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Limitation of Liability</h2>
        <p>To the maximum extent permitted by law, DealCutter.ai shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law provisions.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. If we make changes, we will provide notice of such changes by updating the Terms on our website. You agree to review the Terms periodically to stay informed about any updates.</p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <address className="not-italic mb-4">
          DealCutter.ai<br />
          <a href="mailto:support@dealcutter.ai" className="text-blue-500 hover:underline">support@dealcutter.ai</a><br />
          535 Queen St E<br />
          Toronto, ON M5A 1V1, Canada<br />
          Phone: (123) 456-7890
        </address>

        <p className="text-sm text-gray-600">These Terms of Service were last updated on May 29, 2024.</p>
      </div>
    </div>
  );
};

export default TermsOfService;
