import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import loadingSpinner from './assets/loading_spinner_transparent.gif'; // Import the loading spinner

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        if (password.length < 8) {
            setError('Password must be at least 8 characters long.');
            return;
        }
        setLoading(true); // Set loading to true when form is submitted
        try {
            const response = await axios.post('/api/register', { email, password });
            if (response.status === 201) {
                navigate('/login', { state: { message: `Registration Successful: please confirm your email at ${email} in order to login` } });
            }
        } catch (error) {
            setError('Registration failed. Please check your details and try again.');
        } finally {
            setLoading(false); // Set loading to false after request is complete
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[rgb(53,68,89)] p-4">
            <Link to="/" className="mb-4 text-center">
                <h4 className="font-bold text-2xl text-white lobster-regular">dealcutter.ai</h4>
            </Link>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-4">Register</h2>
                {error && <p className="mb-4 text-red-600">{error}</p>}
                <form onSubmit={handleRegister}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Password:</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-full p-2 border border-gray-300 rounded mt-1"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 px-3 text-gray-600 hover:text-gray-800"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white py-2 rounded hover:bg-blue-700"
                        style={{ backgroundColor: 'rgb(31, 41, 55)' }}
                    >
                        {loading ? <img src={loadingSpinner} alt="Loading..." className="w-6 h-6 mx-auto" /> : 'Register'}
                    </button>
                </form>
                <div className="mt-4">
                    <a href="/login" className="hover:underline" style={{ color: 'rgb(31, 41, 55)' }}>Login</a>
                </div>
            </div>
        </div>
    );
};

export default Register;
