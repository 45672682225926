import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loadingSpinner from './assets/loading_spinner_transparent.gif';
import { EyeIcon, XMarkIcon, InboxIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';

const ShoppingList = ({ initialThreads, loading }) => {
    const [threads, setThreads] = useState(initialThreads || []);
    const [highlightedRows, setHighlightedRows] = useState([]);
    const [showMessageHistory, setShowMessageHistory] = useState(false);
    const [showDealSummaryModal, setShowDealSummaryModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewNegotiationModal, setShowNewNegotiationModal] = useState(false);
    const [messageHistory, setMessageHistory] = useState([]);
    const [currentThread, setCurrentThread] = useState({ subject: '', shop_name: '', shop_email: '', goal: '', tone: '', key_info: '', status: '', deal_summary: '' });
    const [editThread, setEditThread] = useState({ goal: '', tone: '', key_info: '' });
    const [newNegotiation, setNewNegotiation] = useState({ email: '', goal: '', tone: '', keyInfo: '' });

    const navigate = useNavigate();

    useEffect(() => {
        fetchThreads();
    }, []);

    const fetchThreads = async () => {
        try {
            const response = await fetch('/api/threads', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setThreads(data);
        } catch (error) {
            console.error('Error fetching threads:', error);
        }
    };

    const fetchMessageHistory = async (threadId, subject, shopName, shopEmail, goal, tone, keyInfo, status, dealSummary) => {
        try {
            const response = await fetch(`/api/thread-history/${threadId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            setMessageHistory(sortedData);
            setCurrentThread({ id: threadId, subject, shop_name: shopName, shop_email: shopEmail, goal, tone, key_info: keyInfo, status, deal_summary: dealSummary });
            setShowMessageHistory(true);
        } catch (error) {
            console.error('Error fetching message history:', error);
        }
    };

    const openDealSummaryModal = (thread) => {
        setCurrentThread(thread);
        setShowDealSummaryModal(true);
    };

    const closeDealSummaryModal = () => {
        setShowDealSummaryModal(false);
        setCurrentThread({ subject: '', shop_name: '', shop_email: '', goal: '', tone: '', key_info: '', status: '', deal_summary: '' });
    };

    const openEditModal = (thread) => {
        setCurrentThread(thread);
        setEditThread({ goal: thread.goal, tone: thread.tone, key_info: thread.key_info });
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setEditThread({ goal: '', tone: '', key_info: '' });
        setCurrentThread({ subject: '', shop_name: '', shop_email: '', goal: '', tone: '', key_info: '', status: '', deal_summary: '' });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditThread((prev) => ({ ...prev, [name]: value }));
    };

    const updateThreadInfo = async () => {
        try {
            const response = await fetch(`/api/update-thread/${currentThread.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(editThread)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Update the thread information in the table
            const updatedThreads = threads.map((thread) =>
                thread.id === currentThread.id ? { ...thread, ...editThread } : thread
            );
            setThreads(updatedThreads);
            closeEditModal();
        } catch (error) {
            console.error('Error updating thread:', error);
        }
    };

    const closeThreadModal = () => {
        setShowMessageHistory(false);
        setMessageHistory([]);
        setCurrentThread({ subject: '', shop_name: '', shop_email: '', goal: '', tone: '', key_info: '', status: '', deal_summary: '' });
    };

    const openNewNegotiationModal = () => {
        setShowNewNegotiationModal(true);
    };

    const closeNewNegotiationModal = () => {
        setShowNewNegotiationModal(false);
        setNewNegotiation({ email: '', goal: '', tone: '', keyInfo: '' });
    };

    const handleNewNegotiationChange = (e) => {
        const { name, value } = e.target;
        setNewNegotiation((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmitNewNegotiation = async () => {
        if (!validateEmail(newNegotiation.email)) {
            alert('Please enter a valid email address.');
            return;
        }
        try {
            console.log('Submitting new negotiation:', newNegotiation);  // Log the data being sent
            const response = await fetch('/api/new-negotiation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(newNegotiation)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('New negotiation added:', data);
            setNewNegotiation({ email: '', goal: '', tone: '', keyInfo: '' });
            closeNewNegotiationModal();
            fetchThreads(); // Refetch threads after successful submission
            navigate('/home', { state: { successMessage: 'Successfully added new Negotiation thread' } }); // Navigate to home with success message
        } catch (error) {
            console.error('Error adding new negotiation:', error);
        }
    };


    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+$/;
        return re.test(email);
    };

    const displayStatus = (status) => {
        switch (status) {
            case 'none_sent':
                return 'drafting';
            case 'initial_sent':
            case 'awaiting_reply':
                return 'awaiting\nreply';
            case 'need_to_respond':
                return 'responding';
            case 'ready_to_propose':
            case 'deal_proposed':
                return 'deal\nreached';
            case 'no_deal':
                return 'no\ndeal';
            case 'alt_proposal':
                return 'alt\ndeal\nreached';
            case 'email_bounce':
                return 'email\nbounced';
            default:
                return status;
        }
    };

    return (
        <div className="px-2">
            <div className="flex flex-col items-center mb-4">
                <h1 className="text-2xl font-bold text-center mt-6 top-0 bg-white">Negotiation Threads</h1>
                <button
                    className="mt-2 bg-green-600 text-white py-2 px-4 rounded max-w-64 w-full"
                    onClick={openNewNegotiationModal}
                >
                    <h3>Add Negotiation</h3>
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200 text-sm table-fixed">
                    <thead>
                        <tr>
                            <th className="py-2 px-2 border-b text-left	">Goal</th>
                            <th className="py-2 px-2 border-b text-left	">Status</th>
                            <th className="py-2 pr-4 border-b text-left	">Msgs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="4" className="py-4 text-center">
                                    <img src={loadingSpinner} alt="Loading..." className="w-8 h-8 mx-auto" />
                                </td>
                            </tr>
                        ) : (
                            threads.map((thread, index) => (
                                <tr
                                    key={index}
                                    className={`hover:bg-gray-100 ${highlightedRows.includes(thread.id) ? 'bg-yellow-100' : ''}`}
                                >
                                    <td className="py-2 px-2 border-b break-words" style={{ maxWidth: '30%' }}>
                                        <i>{thread.shop_email}:</i> {thread.goal}
                                        <button
                                            onClick={() => openEditModal(thread)}
                                            className="ml-2 py-1 px-2 rounded"
                                        >
                                            <Cog6ToothIcon className="w-4 h-4" />
                                        </button>
                                    </td>
                                    <td className="py-2 px-2 border-b break-words whitespace-pre-line">
                                        {thread.status === 'ready_to_propose' || thread.status === 'deal_proposed' ? (
                                            <>
                                            {displayStatus(thread.status)}
                                            <button
                                                onClick={() => openDealSummaryModal(thread)}
                                                className="py-1 px-2 rounded"
                                            >
                                                <EyeIcon className="w-4 h-4" />
                                            </button>
                                            </>
                                        ) : (
                                            displayStatus(thread.status)
                                        )}
                                    </td>
                                    <td className="py-2 px-1 border-b">
                                        <div className="flex flex-col space-y-2">
                                            <button
                                                onClick={() => fetchMessageHistory(thread.id, thread.subject, thread.shop_name, thread.shop_email, thread.goal, thread.tone, thread.key_info, thread.status, thread.deal_summary)}
                                                className="py-1 px-1 rounded"
                                            >
                                                <EyeIcon className="w-4 h-4" />
                                            </button>
                                            <a href={thread.thread_link} target="_blank" rel="noopener noreferrer" className="py-1 px-1 rounded">
                                                <InboxIcon className="w-4 h-4" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {showMessageHistory && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
                    <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg mx-1">
                        <div className="flex justify-between items-center border-b">
                            <h2 className="text-lg font-semibold">Messages with {currentThread.shop_name}</h2>
                            <button onClick={closeThreadModal}>
                                <XMarkIcon className="w-6 h-6" />
                            </button>
                        </div>
                        <div className="overflow-y-auto max-h-96 mt-4 space-y-2">
                            {messageHistory.map((msg) => (
                                <div key={msg.id} className={`flex ${msg.from === 'user' ? 'justify-end' : 'justify-start'}`}>
                                    <div className={`p-2 rounded-lg ${msg.from === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}>
                                        <p className="text-sm"><strong>{msg.from === 'user' ? 'You' : msg.from}</strong>: {msg.body}</p>
                                        <p className="text-xs mt-1">{new Date(msg.created_at).toLocaleString()}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {showDealSummaryModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
                    <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg mx-1">
                        <div className="flex justify-between items-center border-b">
                            <h2 className="text-lg font-semibold">Deal Summary</h2>
                            <button onClick={closeDealSummaryModal}>
                                <XMarkIcon className="w-6 h-6" />
                            </button>
                        </div>
                        <div className="mt-4">
                            <p>{currentThread.deal_summary}</p>
                        </div>
                    </div>
                </div>
            )}

          {showEditModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
                  <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg mx-1">
                      <div className="flex justify-between items-center border-b pb-2">
                          <h2 className="text-lg font-semibold">Edit Thread Information</h2>
                          <button onClick={closeEditModal}>
                              <XMarkIcon className="w-6 h-6" />
                          </button>
                      </div>
                      <div className="overflow-y-auto max-h-96 mt-4 space-y-4">
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Goal</label>
                              <input
                                  type="text"
                                  name="goal"
                                  value={editThread.goal}
                                  onChange={handleEditChange}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Tone</label>
                              <input
                                  type="text"
                                  name="tone"
                                  value={editThread.tone}
                                  onChange={handleEditChange}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Key Info</label>
                              <textarea
                                  name="key_info"
                                  value={editThread.key_info}
                                  onChange={handleEditChange}
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  rows="4"
                              />
                          </div>
                          <div className="mt-4 flex justify-end">
                              <button
                                  onClick={closeEditModal}
                                  className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                              >
                                  Cancel
                              </button>
                              <button
                                  onClick={updateThreadInfo}
                                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                              >
                                  Update
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          )}

          {showNewNegotiationModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
                  <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg mx-1">
                      <div className="flex justify-between items-center border-b pb-2">
                          <h2 className="text-lg font-semibold">New Negotiation</h2>
                          <button onClick={closeNewNegotiationModal}>
                              <XMarkIcon className="w-6 h-6" />
                          </button>
                      </div>
                      <div className="overflow-y-auto max-h-96 mt-4 space-y-4">
                          <div>
                              <label className="block text-sm font-medium text-gray-700">To</label>
                              <input
                                  type="email"
                                  name="email"
                                  value={newNegotiation.email}
                                  onChange={handleNewNegotiationChange}
                                  placeholder="example@example.com"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Name of Person/Business</label>
                              <input
                                  name="name"
                                  value={newNegotiation.name}
                                  onChange={handleNewNegotiationChange}
                                  placeholder="Corner Shop, Philip Accounting, or John"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Goal</label>
                              <textarea
                                  name="goal"
                                  value={newNegotiation.goal}
                                  onChange={handleNewNegotiationChange}
                                  placeholder="&quot;Get a discount for www.product.com/product/123. Try for at least 10%.&quot;, &quot;Get my spouse to take the kids for Thursday night so I can go out.&quot;"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  rows="4"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Tone to use</label>
                              <textarea
                                  name="tone"
                                  value={newNegotiation.tone}
                                  onChange={handleNewNegotiationChange}
                                  placeholder="&quot;Hey there!&quot;, &quot;To Whom it may concern...&quot;, &quot;Yooooo whats good&quot;"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  rows="4"
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Additional Info to Achieve Goal</label>
                              <textarea
                                  name="keyInfo"
                                  value={newNegotiation.keyInfo}
                                  onChange={handleNewNegotiationChange}
                                  placeholder="&quot;If needed my address is 22 Home St. Toronto&quot;, &quot;it's their birthday today&quot;, &quot;They're my old college classmate&quot;"
                                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  rows="4"
                              />
                          </div>
                          <div className="mt-4 flex justify-end">
                              <button
                                  onClick={closeNewNegotiationModal}
                                  className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                              >
                                  Cancel
                              </button>
                              <button
                                  onClick={handleSubmitNewNegotiation}
                                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                              >
                                  Submit
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          )}
        </div>
    );
};

export default ShoppingList;
