import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/forgot-password', { email });
            if (response.status === 200) {
                setMessage('Password reset email sent. Please check your email.');
            }
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[rgb(53,68,89)] p-4">
            <Link to="/" className="mb-4 text-center">
                <h4 className="font-bold text-2xl text-white lobster-regular">dealcutter.ai</h4>
            </Link>
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
                {message && <p className="mb-4 text-green-600">{message}</p>}
                {error && <p className="mb-4 text-red-600">{error}</p>}
                <form onSubmit={handleForgotPassword}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white py-2 rounded hover:bg-blue-700"
                        style={{ backgroundColor: 'rgb(31, 41, 55)' }}
                    >
                        Send Reset Email
                    </button>
                </form>
                <div className="mt-4">
                    <a href="/login" className="hover:underline" style={{ color: 'rgb(31, 41, 55)' }}>Login</a>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
