import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import loadingSpinner from './assets/loading_spinner_transparent.gif';
import { XMarkIcon } from '@heroicons/react/24/solid';

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 max-h-full overflow-y-auto relative">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">{title}</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        <XMarkIcon className="h-6 w-6" />
                    </button>
                </div>
                <div className="overflow-y-auto max-h-[calc(100vh-10rem)]">
                    {children}
                </div>
            </div>
        </div>
    );
};

const Settings = () => {
    const [connectedEmail, setConnectedEmail] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberMessage, setPhoneNumberMessage] = useState('');
    const [inputPhoneNumber, setInputPhoneNumber] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [userDetails, setUserDetails] = useState({
        first_name: '',
        last_name: '',
        shipping_city: '',
        haggle_creativity_level: 'medium',
    });
    const [isEditingUserDetails, setIsEditingUserDetails] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showNewNegotiationModal, setShowNewNegotiationModal] = useState(false);
    const [newNegotiation, setNewNegotiation] = useState({ email: '', goal: '', tone: '', keyInfo: '' });

    const navigate = useNavigate();

    // Axios instance with JWT token
    const api = axios.create({
        baseURL: '/', // Base URL for your API
    });

    api.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    useEffect(() => {
        const fetchAccountStatus = async () => {
            setLoading(true); // Start loading
            try {
                const response = await api.get('/api/account-status');
                if (response.data.email) {
                    setConnectedEmail(response.data.email);
                }
                if (response.data.phone_number) {
                    setPhoneNumber(response.data.phone_number);
                }
            } catch (error) {
                console.error('Error fetching account status', error);
            }
            setLoading(false); // End loading
        };

        const fetchUserInfo = async () => {
            try {
                const response = await api.get('/api/user-info');
                setUserDetails(response.data);
                console.log('User details:', response.data);
                if (!response.data.first_name || !response.data.last_name || !response.data.shipping_city) {
                    setIsEditingUserDetails(true);
                }
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        };

        fetchAccountStatus();
        fetchUserInfo();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleUserDetailsSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Start submitting
        try {
            const response = await api.post('/api/user-info', userDetails);
            if (response.status === 200) {
                setIsEditingUserDetails(false);
                setMessage('User details updated successfully.');
            } else {
                throw new Error(response.data.error);
            }
        } catch (error) {
            console.error('Error updating user details', error);
            setMessage(error.response?.data?.error || error.message || 'An error occurred');
        }
        setIsSubmitting(false); // End submitting
    };

    const handleConnect = async () => {
        try {
            const response = await api.get('/google-authorize');
            window.location.href = response.data.authorization_url;
        } catch (error) {
            console.error('Error connecting to Google account', error);
            setMessage('Failed to connect to Google account.');
        }
    };

    const handleDisconnect = async () => {
        try {
            const response = await api.post('/google-disconnect');
            setConnectedEmail(null);
            setMessage('Google account disconnected successfully.');
        } catch (error) {
            console.error('Error disconnecting Google account', error);
            setMessage('Failed to disconnect Google account.');
        }
    };

    const handlePhoneNumberSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Start submitting
        const phoneNumberPattern = /^(\d{10}|\d{3}-\d{3}-\d{4}|1\d{10}|1-\d{3}-\d{3}-\d{4})$/;
        if (!phoneNumberPattern.test(inputPhoneNumber)) {
            setPhoneNumberMessage('Please enter a valid US or Canadian phone number. Acceptable formats: 1234567890, 123-456-7890, 11234567890, 1-123-456-7890.');
            setIsSubmitting(false); // End submitting
            return;
        }

        // Normalize the phone number to the format 1xxxxxxxxxx
        let normalizedPhoneNumber = inputPhoneNumber.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
        if (normalizedPhoneNumber.length === 10) {
            normalizedPhoneNumber = '1' + normalizedPhoneNumber; // Add '1' at the beginning if it's a 10-digit number
        }

        try {
            const response = await api.post('/api/update-phone-number', { phone_number: normalizedPhoneNumber });
            if (response.status !== 200) {
                throw new Error(response.data.error);
            }
            setPhoneNumberMessage('Phone number updated successfully.');
            setPhoneNumber(normalizedPhoneNumber); // Update the state to show the normalized phone number
            setIsEditing(false); // Exit edit mode
        } catch (error) {
            console.error('Error updating phone number', error);
            setPhoneNumberMessage(error.response?.data?.error || error.message || 'An error occurred');
        }
        setIsSubmitting(false); // End submitting
    };

    const openNewNegotiationModal = () => {
        setShowNewNegotiationModal(true);
    };

    const closeNewNegotiationModal = () => {
        setShowNewNegotiationModal(false);
        setNewNegotiation({ email: '', goal: '', tone: '', keyInfo: '' });
    };

    const handleNewNegotiationChange = (e) => {
        const { name, value } = e.target;
        setNewNegotiation((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmitNewNegotiation = async () => {
        if (!validateEmail(newNegotiation.email)) {
            alert('Please enter a valid email address.');
            return;
        }
        try {
            const response = await api.post('/api/new-negotiation', newNegotiation);
            if (response.status !== 201) {
                throw new Error('Network response was not ok');
            }
            console.log('New negotiation added:', response.data);
            setNewNegotiation({ email: '', goal: '', tone: '', keyInfo: '' });
            closeNewNegotiationModal();
            navigate('/home', { state: { successMessage: 'Successfully added new Negotiation thread' } });
        } catch (error) {
            console.error('Error adding new negotiation:', error);
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const openHowItWorksModal = () => {
        setIsModalOpen(true);
    };

    const closeHowItWorksModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4 text-center">Settings</h1>
            {loading ? (
                <div className="flex justify-center text-center h-40">
                    <img src={loadingSpinner} alt="Loading..." className="w-8 h-8" />
                </div>
            ) : (
                <>
                    {connectedEmail ? (
                        <div className="bg-green-200 text-green-800 p-4 rounded mb-6 text-center">                          
                            <p className="mb-2">All set. Create a new negotiation below: <p className="text-sm cursor-pointer text-blue-400 hover:underline" onClick={openHowItWorksModal}>(learn how it works)</p></p>
                            <button
                                onClick={openNewNegotiationModal}
                                className="bg-green-600 text-white py-2 px-4 rounded max-w-64 w-full"
                            >
                                <h3>New Negotiation</h3>
                            </button>
                          
                            
                        </div>
                    ) : (
                        <div className="bg-yellow-200 text-yellow-800 p-4 rounded mb-6">
                            <h3 className="font-bold">Welcome! Let's get started:</h3>
                            {!connectedEmail && (
                                <p className="mt-2">Connect your Gmail so we can negotiate for you. We recommend you use a secondary email.</p>
                            )}
                        </div>
                    )}
                    <div className="mb-6">
                        <div className="bg-white p-4 rounded shadow mb-6">
                            <h4 className="font-bold mb-2">Negotiation Config</h4>
                            <p className="mb-4 text-sm">Details the AI will use when negotiating for you.</p>
                            {isEditingUserDetails ? (
                                <form onSubmit={handleUserDetailsSubmit}>
                                    <label className="block mb-2">First Name:</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={userDetails.first_name}
                                        onChange={handleInputChange}
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                    />

                                    <label className="block mb-2">Last Name:</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={userDetails.last_name}
                                        onChange={handleInputChange}
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                    />

                                    <label className="block mb-2">Shipping City:</label>
                                    <input
                                        type="text"
                                        name="shipping_city"
                                        value={userDetails.shipping_city}
                                        onChange={handleInputChange}
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                    />

                                    <label className="block mb-2">Haggle Creativity Level:</label>
                                    <div className="flex mb-4">
                                        <label className="mr-4">
                                            <input
                                                type="radio"
                                                name="haggle_creativity_level"
                                                value="low"
                                                checked={userDetails.haggle_creativity_level === 'low'}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Low
                                        </label>
                                        <label className="mr-4">
                                            <input
                                                type="radio"
                                                name="haggle_creativity_level"
                                                value="medium"
                                                checked={userDetails.haggle_creativity_level === 'medium'}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            Medium
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="haggle_creativity_level"
                                                value="high"
                                                checked={userDetails.haggle_creativity_level === 'high'}
                                                onChange={handleInputChange}
                                                className="mr-2"
                                            />
                                            High
                                        </label>
                                    </div>
                                    <button type="submit" className="bg-green-600 text-white py-2 px-4 rounded w-full">
                                        {isSubmitting ? <img src={loadingSpinner} alt="Loading..." className="w-4 h-4 inline mr-2" /> : 'Update Info'}
                                    </button>
                                </form>
                            ) : (
                                <>
                                    <div className="p-2 bg-[rgb(242,248,255)] rounded">
                                        <table className="w-full text-sm">
                                            <tbody>
                                                <tr>
                                                    <td className="font-normal">First Name:</td>
                                                    <td><i>{userDetails.first_name}</i></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-normal">Last Name:</td>
                                                    <td><i>{userDetails.last_name}</i></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-normal">Shipping City:</td>
                                                    <td><i>{userDetails.shipping_city}</i></td>
                                                </tr>
                                                <tr>
                                                    <td className="font-normal">Haggle Creativity Level:</td>
                                                    <td><i>{userDetails.haggle_creativity_level}</i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button
                                        onClick={() => setIsEditingUserDetails(true)}
                                        className="mt-2 bg-gray-600 text-white text-sm py-2 px-4 rounded w-full"
                                    >
                                        Edit Info
                                    </button>
                                </>
                            )}
                            <p className={`mt-4 ${message.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                                {message}
                            </p>
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="bg-white p-4 rounded shadow mb-6">
                            <h4 className="font-bold mb-2">Gmail Account</h4>
                            {connectedEmail ? (
                                <div className="flex flex-col items-center text-sm">
                                    <div className="p-2 bg-[rgb(242,248,255)] w-full rounded">
                                        <p>Using <i>{connectedEmail}</i> to negotiate</p>
                                    </div>
                                    <button onClick={handleDisconnect} className="mt-2 bg-gray-600 text-white py-2 px-4 rounded w-full">Disconnect</button>
                                </div>
                            ) : (
                                <div>
                                    <p>We recommend you use a secondary email to negotiate with the AI, to save your inbox 😛</p>
                                    <button onClick={handleConnect} className="mt-2 bg-blue-600 text-white py-2 px-4 rounded w-full">Connect to Google Account</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="bg-white p-4 rounded shadow mb-6">
                            <h4 className="font-bold mb-2">Phone Number</h4>
                            <p className="text-sm mb-2">Get text updates on your negotiations</p>
                            {phoneNumber ? (
                                <div className="flex-col items-center text-sm">
                                    <div className="p-2 bg-[rgb(242,248,255)] w-full rounded">
                                        <p><i>{phoneNumber}</i> connected</p>
                                    </div>
                                    {!isEditing && (
                                        <button onClick={() => setIsEditing(true)} className="mt-2 bg-gray-600 text-white py-2 px-4 rounded w-full">
                                            Edit Phone Number
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center text-sm">
                                    <form onSubmit={handlePhoneNumberSubmit} className="mt-4">
                                        <input
                                            type="text"
                                            value={inputPhoneNumber}
                                            onChange={(e) => setInputPhoneNumber(e.target.value)}
                                            placeholder="Enter your phone number"
                                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                                        />
                                        <button type="submit" className="bg-green-600 text-white py-2 px-4 rounded w-full">Add Phone Number</button>
                                    </form>
                                </div>
                            )}
                            {isEditing && (
                                <form onSubmit={handlePhoneNumberSubmit} className="mt-4">
                                    <input
                                        type="text"
                                        value={inputPhoneNumber}
                                        onChange={(e) => setInputPhoneNumber(e.target.value)}
                                        placeholder="Enter your phone number"
                                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                                    />
                                    <button type="submit" className="bg-green-600 text-white py-2 px-4 rounded w-full">
                                        {isSubmitting ? <img src={loadingSpinner} alt="Loading..." className="w-4 h-4 inline mr-2" /> : phoneNumber ? 'Update Phone Number' : 'Add Phone Number'}
                                    </button>
                                </form>
                            )}
                            {phoneNumberMessage && (
                                <p className={`mt-4 ${phoneNumberMessage.includes('successfully') ? 'text-green-600' : 'text-red-600'}`}>
                                    {phoneNumberMessage}
                                </p>
                            )}
                        </div>
                    </div>
                </>
            )}
            <Modal
                isOpen={isModalOpen}
                onClose={closeHowItWorksModal}
                title="How It Works"
                className="mx-2"
            >
                <h3 className="font-bold mb-2">1. Start a new Negotiation</h3>
                <p className="mb-4">Tell us who to contact, your goal in the conversation, and any extra info that could be helpful for getting what you want. You can monitor the progress of your negotiations on the <NavLink to="/home" className="text-blue-600">Shopping List</NavLink> page</p>
                <h3 className="font-bold mb-2">2. The AI will start a thread with your contact</h3>
                <p className="mb-4">We use your gmail so it seems like it's coming from you. We use a tone and voice that you can configure, but overall is respectful and human, and our AI only talks about attaining your goal, it won't say random things.</p>
                <h3 className="font-bold mb-2">3. Once we have agreed on a deal, we will inform you</h3>
                <p>You'll receive a text back from us when we have negotiated a deal that beats the price you entered. You can then go right into your inbox and "take over" the thread to confirm the deal with the shop. This way you have all the context on how the deal was negotiated.</p>
                <hr className="my-4" />
            </Modal>

            {showNewNegotiationModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
                    <div className="bg-white p-4 rounded shadow-lg w-full max-w-lg mx-1">
                        <div className="flex justify-between items-center border-b">
                            <h2 className="text-lg font-semibold">New Negotiation</h2>
                            <button onClick={closeNewNegotiationModal}>
                                <XMarkIcon className="w-6 h-6" />
                            </button>
                        </div>
                        <div className="overflow-y-auto max-h-96">
                            <div className="mt-4 space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">To</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={newNegotiation.email}
                                        onChange={handleNewNegotiationChange}
                                        placeholder="example@example.com"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Name of Person/Business</label>
                                    <input
                                        name="name"
                                        value={newNegotiation.name}
                                        onChange={handleNewNegotiationChange}
                                        placeholder="Corner Shop, Philip Accounting, or John"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Goal</label>
                                    <textarea
                                        name="goal"
                                        value={newNegotiation.goal}
                                        onChange={handleNewNegotiationChange}
                                        placeholder="&quot;Get a discount for www.product.com/product/123. Try for at least 10%.&quot;, &quot;Get my spouse to take the kids for Thursday night so I can go out.&quot;"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        rows="4"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tone</label>
                                    <textarea
                                        name="tone"
                                        value={newNegotiation.tone}
                                        onChange={handleNewNegotiationChange}
                                        placeholder='&quot;Hey there!&quot;, &quot;To Whom it may concern...&quot;, &quot;Yooooo whats good&quot;'
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        rows="4"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Key Info</label>
                                    <textarea
                                        name="keyInfo"
                                        value={newNegotiation.keyInfo}
                                        onChange={handleNewNegotiationChange}
                                        placeholder="&quot;If needed my address is 22 Home St. Toronto&quot;, &quot;it's their birthday today&quot;, &quot;They're my old college classmate&quot;"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        rows="4"
                                    />
                                </div>
                                <div className="mt-4 flex justify-end">
                                    <button
                                        onClick={closeNewNegotiationModal}
                                        className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSubmitNewNegotiation}
                                        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
